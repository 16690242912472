import React, { Reducer, useReducer, useState } from "react";
import { Text } from "../Helper/text";
import styles from "./Consultation.module.sass";
import emailjs from "emailjs-com";

function isValidEmailAddress(email: string = ""): boolean {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email.toLowerCase());
}

type Action = {
  url?: string;
  messages?: Message[];
  input: "firstName" | "lastName" | "email" | "phone" | "";
  save: boolean;
};

type Message = {
  owner: string | null;
  text: string;
  options: {
    title?: string;
    action: Action;
  }[];
};

interface ConsultationState {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  undefined: string;
  error: string;
}

let feedInit: Message[] = [
  {
    owner: "Casey Nankivell",
    text: "Schedule a call with one of our property professionals or let us know how we can help.",
    options: [
      {
        title: "WhatsApp",
        action: {
          input: "",
          save: false,
          url: "https://api.whatsapp.com/send?phone=+442071181162",
        },
      },
      {
        title: "Phone Call",
        action: {
          input: "",
          save: false,
          messages: [
            {
              owner: null,
              text: "Phone Call",
              options: [],
            },
            {
              owner: "Casey Nankivell",
              text: "Please enter your phone number.",
              options: [
                {
                  action: {
                    save: false,
                    input: "phone",
                  },
                },
              ],
            },
          ],
        },
      },
      {
        title: "Email",
        action: {
          input: "",
          save: false,
          messages: [
            {
              owner: null,
              text: "Email",
              options: [],
            },
            {
              owner: "Casey Nankivell",
              text: "Please enter your email addresss.",
              options: [
                {
                  action: {
                    save: false,
                    input: "email",
                  },
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

const actions = {
  lastName: [
    {
      owner: "Casey Nankivell",
      text: "Thank you for your enquiry. We aim to be in contact within 48hours.",
      options: [
        {
          action: {
            input: "",
            save: true,
          },
        },
      ],
    },
  ],
  phone: [
    {
      owner: "Casey Nankivell",
      text: "Please enter your first name.",
      options: [
        {
          action: {
            save: false,
            input: "firstName",
          },
        },
      ],
    },
  ],
  email: [
    {
      owner: "Casey Nankivell",
      text: "Please enter your first name.",
      options: [
        {
          action: {
            save: false,
            input: "firstName",
          },
        },
      ],
    },
  ],
  firstName: [
    {
      owner: "Casey Nankivell",
      text: "Please enter your family name.",
      options: [
        {
          action: {
            save: false,
            input: "lastName",
          },
        },
      ],
    },
  ],
};

export function Consultation() {
  const [open, setOpen] = useState<boolean>(false);
  const [feed, setFeed] = useState<Message[]>(feedInit);

  const [state, setState] = useReducer<
    Reducer<ConsultationState, Partial<ConsultationState>>
  >((state, newState) => ({ ...state, ...newState }), {
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    undefined: "",
    error: "",
  });

  const addToFeed = (action: Action) => {
    if (action.messages) {
      const messages = action.messages;
      const tempFeed = [...feed];
      tempFeed[tempFeed.length - 1].options = [
        {
          action: {
            save: false,
            input: "",
          },
        },
      ];

      messages.map((message) => tempFeed.push(message));
      setFeed(tempFeed);
    }
  };

  const submitInput = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    var key = feed[feed.length - 1].options[0].action["input"];

    if (
      key === "firstName" ||
      key === "lastName" ||
      key === "email" ||
      key === "phone"
    ) {
      if (key === "email") {
        if (isValidEmailAddress(state[key]) === false) {
          return setState({ error: "Please enter a valid email address" });
        }
      }

      if (key === "phone") {
        if (
          /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm.test(
            state[key]
          ) === false
        ) {
          return setState({ error: "Please enter a valid phone number" });
        }
      }

      if (state[key] === "") {
        return setState({
          error: `Please enter your ${key
            .split(/(?=[A-Z])/)
            .join(" ")
            .toLowerCase()}`,
        });
      }

      const newActions = actions[key];

      if (newActions[0].options[0]) {
        const saveAction = newActions[0].options[0];
        if (saveAction.action.save) {
          // await API.graphql({query: createConsultationChat, variables: {input: {
          //   firstName: state.firstName,
          //   lastName: state.lastName,
          //   email: state.email,
          //   phone: state.phone
          // }}, authMode: "API_KEY", authToken: "da2-236rf363rbevlnvpt3ns3t6qky"})
          emailjs.send(
            "gmail",
            "template_pxsuo5e",
            { ...state, contactType: "Consulation Enquiry" },
            "NpPDsAhPcFBT3yvHm"
          );
        }
      }

      const tempFeed = [...feed];
      tempFeed.push({
        owner: null,
        text: state[key],
        options: [
          {
            action: {
              save: false,
              input: "",
            },
          },
        ],
      });
      tempFeed[tempFeed.length - 2].options = [];

      newActions.map((action) => {
        tempFeed.push(action as Message);
      });

      setFeed(tempFeed);
      setState({ error: "" });
    }
  };

  const lastKey = feed[feed.length - 1].options[0].action["input"];

  return (
    <div className={styles.consultationContainer}>
      <div
        style={{
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "auto 20px",
        }}
        onClick={() => setOpen(!open)}
      >
        <Text title={"Contact Us"} titleSize="Heading 4" accent={false} />

        <svg
          height="21"
          viewBox="0 0 21 21"
          width="21"
          xmlns="http://www.w3.org/2000/svg"
          style={{ flex: 1 }}
        >
          <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(1 1)"
          >
            <path d="m8.5 4.5c-1.65685425 0-3 1.34314575-3 3v5c0 1.6568542 1.34314575 3 3 3l2.468-.001 1.7152977 2.4298518c.1639804.2320786.4176379.3818336.6960544.4156462l.1206479.0072945c.5128358 0 .9355072-.3860402.9932723-.8833789l.0067277-.1166211.001-1.8527925.999.001c1.6568542 0 3-1.3431458 3-3v-5c0-1.65685425-1.3431458-3-3-3z" />
            <path d="m5.5 12.5-2 2v-4h-.90625561c-1.09544195 0-1.98704628-.88123276-1.9998631-1.97659973l-.07020561-6c-.01292361-1.10449389.87196894-2.01033976 1.97646283-2.02326337.00779976-.00009127.01559998-.0001369.02340027-.0001369h8.97646122c1.1045695 0 2 .8954305 2 2v2" />
          </g>
        </svg>
      </div>
      <div
        className={`${styles.consultationContent} ${open ? styles.active : ""}`}
      >
        <div className={styles.consultationFeed}>
          <div>
            <div>
              <p style={{ margin: 0 }}>Today</p>
            </div>

            {feed.map((message: Message, key: number) => (
              <div
                key={key}
                className={`${styles.consultationFeedGroup} ${
                  message.owner ? "" : " " + styles.userMessage
                }`}
              >
                <div className={styles.consultationFeedMessages}>
                  <div
                    className={
                      styles.consultationFeedItem + " " + styles.message
                    }
                  >
                    <p style={{ margin: 0 }}>{message.text}</p>
                  </div>
                  {message.options?.map(
                    (option, index) =>
                      option.action &&
                      (option.action["url"] ? (
                        <div
                          key={index}
                          className={
                            styles.consultationFeedItem + " " + styles.option
                          }
                        >
                          <a
                            href={option.action.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p style={{ margin: 0 }}>{option.title}</p>
                          </a>
                        </div>
                      ) : option.action["messages"] ? (
                        <div
                          key={index}
                          className={
                            styles.consultationFeedItem + " " + styles.option
                          }
                        >
                          <p
                            onClick={() => addToFeed(option.action)}
                            style={{ margin: 0 }}
                          >
                            {option.title}
                          </p>
                        </div>
                      ) : null)
                  )}
                </div>
              </div>
            ))}

            {feed[feed.length - 1].options &&
              feed[feed.length - 1].options[0].action?.input && (
                <div className={styles.consultationInput}>
                  <p style={{ color: "red", margin: 0 }}>{state.error}</p>
                  <form
                    onSubmit={submitInput}
                    className={styles.consultationForm}
                  >
                    <input
                      type="text"
                      onChange={(e) =>
                        setState({ [lastKey as string]: e.target.value })
                      }
                      value={state[lastKey ? lastKey : "phone"]}
                      placeholder={feed[feed.length - 1].text}
                    />
                    <button type="submit">Submit</button>
                  </form>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Consultation;
